<template>
  <div class="text-right">
    <Button
      :label="$t('global.save')"
      :loading="loadingButton"
      iconPos="right"
      @click="save"
    ></Button>
  </div>

  <Accordion class="mt-4" :multiple="true" :activeIndex="activeIndexes">
    <AccordionTab :header="$t('settings.ratings')">
      <div class="grid">
        <div
          class="col-12 md:col-2 lg align-self-center text-left md:text-center pb-1 md:pb-3"
        >
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <span v-else class="text-lg">{{ $t("settings.every") }}</span>
        </div>
        <div class="col-12 md:col-2 lg:col-1 p-fluid pt-1 md:pt-3">
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <div v-else>
            <InputNumber
              v-model="rating.time"
              :min="1"
              :class="{
                'p-invalid': v$.rating.time.$invalid && submitted,
              }"
            ></InputNumber>
            <ul
              v-if="v$.rating.time.$error && submitted"
              class="p-error text-xs pl-3 m-0 pt-1"
            >
              <li v-for="(error, index) of v$.rating.time.$errors" :key="index">
                {{ $t("global.empty") }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 md:col-3 p-fluid">
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <div v-else>
            <Dropdown
              v-model="rating.timePeriod"
              :options="ratingsDropdown"
              :class="{
                'p-invalid': v$.rating.timePeriod.$invalid && submitted,
              }"
            >
              <template #value="{ value }">
                <template v-if="value">
                  {{ $t(`settings.${value}`, rating.time) }}
                </template>
                <template v-else>{{ $t("settings.choosePeriod") }}</template>
              </template>

              <template #option="{ option }">
                {{ $t(`settings.${option}`, rating.time) }}
              </template>
            </Dropdown>
            <ul
              v-if="v$.rating.timePeriod.$error && submitted"
              class="p-error text-xs pl-3 m-0 pt-1"
            >
              <li
                v-for="(error, index) of v$.rating.timePeriod.$errors"
                :key="index"
              >
                {{ error.$message.replace("[value]", $t("settings.period")) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </AccordionTab>
    <AccordionTab :header="$t('settings.renewals')">
      <div class="grid">
        <div
          class="col-12 md:col-2 lg align-self-center text-left md:text-center pb-1 md:pb-3"
        >
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <span v-else class="text-lg">{{ $t("settings.before") }}</span>
        </div>
        <div class="col-12 md:col-2 lg:col-1 p-fluid pt-1 md:pt-3">
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <div v-else>
            <InputNumber
              v-model="renewal.time"
              :min="1"
              :class="{
                'p-invalid': v$.renewal.time.$invalid && submitted,
              }"
            ></InputNumber>
            <ul
              v-if="v$.renewal.time.$error && submitted"
              class="p-error text-xs pl-3 m-0 pt-1"
            >
              <li
                v-for="(error, index) of v$.renewal.time.$errors"
                :key="index"
              >
                {{ $t("global.empty") }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 md:col-3 p-fluid pb-1 md:pb-3">
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <div v-else>
            <Dropdown
              v-model="renewal.timePeriod"
              :options="renewalsDropdown"
              :class="{
                'p-invalid': v$.renewal.timePeriod.$invalid && submitted,
              }"
            >
              <template #value="{ value }">
                <template v-if="value">
                  {{ $t(`settings.${value}`, renewal.time) }}
                </template>
                <template v-else>{{ $t("settings.choosePeriod") }}</template>
              </template>

              <template #option="{ option }">
                {{ $t(`settings.${option}`, renewal.time) }}
              </template>
            </Dropdown>
            <ul
              v-if="v$.renewal.timePeriod.$error && submitted"
              class="p-error text-xs pl-3 m-0 pt-1"
            >
              <li
                v-for="(error, index) of v$.renewal.timePeriod.$errors"
                :key="index"
              >
                {{ error.$message.replace("[value]", $t("settings.period")) }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-12 md:col-2 align-self-center text-left md:text-center text-lg pt-1 md:pt-3"
        >
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <span v-else>{{ $t("settings.beforeExpiration") }}</span>
        </div>
      </div>
    </AccordionTab>
    <AccordionTab :header="$t('settings.locationVerification')">
      <div class="grid">
        <div
          class="col-12 md:col-4 lg align-self-center text-left md:text-center pb-1 md:pb-3"
        >
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <span v-else class="text-lg">{{ $t("settings.auditorRadius") }}</span>
        </div>
        <div class="col-12 md:col-2 lg:col-1 p-fluid pt-1 md:pt-3">
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <div v-else>
            <InputNumber
              :min="1"
              v-model="radiusValue.radius"
              :class="{
                'p-invalid': v$.radiusValue.radius.$invalid && submitted,
              }"
            ></InputNumber>
            <ul
              v-if="v$.radiusValue.radius.$error && submitted"
              class="p-error text-xs pl-3 m-0 pt-1"
            >
              <li
                v-for="(error, index) of v$.radiusValue.radius.$errors"
                :key="index"
              >
                {{ $t("global.empty") }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-12 md:col-3 align-self-center text-left md:text-center text-lg pt-1 md:pt-3"
        >
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <span v-else>{{ $t("settings.metersFromCompany") }}</span>
        </div>
      </div>
    </AccordionTab>
    <AccordionTab :header="`GDPR (${$t('settings.deleteAccountSettings')})`">
      <div class="grid">
        <div
          class="col-12 md:col-3 lg align-self-center text-left md:text-center pb-1 md:pb-3"
        >
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <span v-else class="text-lg">{{ $t("settings.deleteAccount") }}</span>
        </div>
        <div class="col-12 md:col-2 lg:col-1 p-fluid pt-1 md:pt-3">
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <div v-else>
            <InputNumber
              v-model="deletePeriodObject.time"
              :min="1"
              :class="{
                'p-invalid': v$.deletePeriodObject.time.$invalid && submitted,
              }"
            ></InputNumber>
            <ul
              v-if="v$.deletePeriodObject.time.$error && submitted"
              class="p-error text-xs pl-3 m-0 pt-1"
            >
              <li
                v-for="(error, index) of v$.deletePeriodObject.time.$errors"
                :key="index"
              >
                {{ $t("global.empty") }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 md:col-3 p-fluid">
          <Skeleton
            v-if="loading"
            width="100%"
            height="2rem"
            class="mb-2"
          ></Skeleton>
          <div v-else>
            <Dropdown
              v-model="deletePeriodObject.timePeriod"
              :options="deletePeriodDropdown"
              :class="{
                'p-invalid':
                  v$.deletePeriodObject.timePeriod.$invalid && submitted,
              }"
            >
              <template #value="{ value }">
                <template v-if="value">
                  {{ $t(`settings.${value}`, deletePeriodObject.time) }}
                </template>
                <template v-else>{{ $t("settings.choosePeriod") }}</template>
              </template>

              <template #option="{ option }">
                {{ $t(`settings.${option}`, deletePeriodObject.time) }}
              </template>
            </Dropdown>
            <ul
              v-if="v$.deletePeriodObject.timePeriod.$error && submitted"
              class="p-error text-xs pl-3 m-0 pt-1"
            >
              <li
                v-for="(error, index) of v$.deletePeriodObject.timePeriod
                  .$errors"
                :key="index"
              >
                {{ error.$message.replace("[value]", $t("settings.period")) }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </AccordionTab>
  </Accordion>
</template>

<script>
import { required } from "@/utilities/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { mapGetters } from "vuex";

export default {
  name: "Variables",
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: false,
      loadingButton: false,
      rating: {
        time: null,
        timePeriod: "",
      },
      ratingsDropdown: ["minute", "hour", "day", "week", "month", "year"],
      renewal: {
        time: null,
        timePeriod: "",
      },
      radiusValue: {
        radius: null,
      },
      renewalsDropdown: ["minute", "hour", "day", "week", "month", "year"],
      deletePeriodObject: {
        time: null,
        timePeriod: "",
      },
      deletePeriodDropdown: ["minute", "hour", "day", "week", "month", "year"],
      submitted: false,
      activeIndexes: [],
    };
  },
  validations: {
    rating: {
      time: {
        required,
      },
      timePeriod: {
        required,
      },
    },
    renewal: {
      time: {
        required,
      },
      timePeriod: {
        required,
      },
    },
    radiusValue: {
      radius: {
        required,
      },
    },
    deletePeriodObject: {
      time: {
        required,
      },
      timePeriod: {
        required,
      },
    },
  },
  async mounted() {
    this.loading = true;
    await Promise.all([
      this.$store.dispatch("getRenewalTime"),
      this.$store.dispatch("getRatingTime"),
      this.$store.dispatch("getRadius"),
      this.$store.dispatch("deletePeriod"),
    ]);

    if (Object.keys(this.ratingTime).length > 0) {
      this.rating = { ...this.ratingTime };
    }

    if (Object.keys(this.renewalTime).length > 0) {
      this.renewal = { ...this.renewalTime };
    }

    if (this.radius) {
      this.radiusValue = { ...this.radius };
    }

    if (Object.keys(this.deletePeriod).length > 0) {
      this.deletePeriodObject = { ...this.deletePeriod };
    }

    this.loading = false;
  },
  computed: {
    ...mapGetters(["renewalTime", "ratingTime", "radius", "deletePeriod"]),
  },
  methods: {
    async save() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        this.loadingButton = true;
        await Promise.all([
          this.$store.dispatch("saveRatingTime", this.rating),
          this.$store.dispatch("saveRenewalTime", this.renewal),
          this.$store.dispatch("saveRadius", this.radiusValue.radius),
          this.$store.dispatch("saveDeletePeriod", this.deletePeriodObject),
        ]);

        this.loadingButton = false;
        this.$notification("", this.$t("settings.variablesSaved"));
      } else {
        this.activeIndexes = [0, 1, 2, 3];
      }
    },
  },
};
</script>
